<template>
  <div id="Baggage_Change_Order_fushu">
    <top></top>
    <!-- 主体表格 -->
    <div class="Baggage_Change_Order_body_div">
      <!-- 导出数据 -->
      <div class="vue-json-excel" v-if="Terminal_type == 'pc'">
        <template>
          <download-excel
            class="export-excel-wrapper"
            :data="res"
            :fields="json_fields"
            header="东航行李额收益报表"
            name="东航行李额收益报表.xls"
          >
            <el-button type="success">数据导出</el-button>
          </download-excel>
        </template>
      </div>

      <div
        class="Baggage_Change_Order_body"
        :style="'height:' + table_height + 'px'"
      >
        <table
          style="table-layout: fixed"
          class="Baggage_Change_Order_table"
          border="1"
        >
          <tr>
            <td>姓名</td>
            <td>票号</td>
            <td v-if="Terminal_type == 'pc'">时间</td>
            <td>额度</td>
            <td v-if="Terminal_type == 'pc'">实际里程</td>
            <td v-if="Terminal_type == 'pc'">原始里程</td>
            <td v-if="Terminal_type == 'pc'">卡号</td>
            <td v-if="Terminal_type == 'pc'">卡密</td>
            <td v-if="Terminal_type == 'pc'">支付密码</td>
            <td v-if="Terminal_type == 'pc'">下单管理</td>
          </tr>
          <tr
            v-for="(item, index) in res"
            :key="index"
            @click="mobile(item, index)"
          >
            <td>
              <span>{{ item.passenger_name }}</span>
            </td>
            <td>{{ item.ticket_no }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.create_time }}</td>
            <td>{{ item.baggage_allowance }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.actual_mileage }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.mileage }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.account[0] }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.account[1] }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.account[2] }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.personnel }}</td>
          </tr>
        </table>
      </div>
      <!-- mobile 独有-->
      <van-popup
        v-if="Terminal_type == 'mobile'"
        v-model="show"
        round
        position="bottom"
      >
        <div class="Baggage_Change_Order_popup_div">
          <div class="Baggage_Change_Order_popup_flex">
            <p>姓名：</p>
            <p>
              <span>{{ popup_item.passenger_name }},</span>
            </p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>票号：</p>
            <p>{{popup_item.ticket_no}}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>时间：</p>
            <p>{{popup_item.create_time}}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>额度：</p>
            <p>{{ popup_item.baggage_allowance }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>实际里程：</p>
            <p>{{ popup_item.actual_mileage }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>原始里程：</p>
            <p>{{ popup_item.mileage }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>东航卡号：</p>
            <p v-show="res[res_index]">{{ res[res_index].account[0] }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>东航卡密：</p>
            <p v-show="res[res_index]">{{ res[res_index].account[1] }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>东航支付密码：</p>
            <p v-show="res[res_index]">{{ res[res_index].account[2] }}</p>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
        
    <script>
import top from "../components/BodyComponents.vue";
export default {
  name: "Baggage_Change_Order_fushu",
  data() {
    return {
      table_height: sessionStorage.getItem("table_height"),
      res: [],
      json_fields: {
        姓名: "passenger_name",
        票号: "ticket_no",
        时间: "create_time",
        额度: "baggage_allowance",
        原本支出里程: "mileage",
        实际支出里程: "actual_mileage",
        东航卡信息: "account_information",
        下单管理人员:'personnel',
      },
      res_index: 0,
      popup_item: [],
      show: false,
    };
  },
  components: {
    top,
  },
  created() {
    // 表格展示数据
    this.$https("luggage_allowance_inside/orderlist", "", "get").then((res) => {
      res.data.data.forEach((item, index) => {
        res.data.data[index].account = item.account_information.split(" ");
      });
      this.res = res.data.data;
      console.log(this.res);
    });
  },
  mounted() {
    console.log("进入mounted");
  },
  methods: {
    mobile(item, index) {
      if (this.Terminal_type == "pc") return false;
      this.show = true;
      this.popup_item = item;
      this.res_index = index;
    },
  },
};
</script>
        
    <style scoped>
body {
  background: #fafafa;
}
/* 穿透element-ui 消息提示 */
</style>